@font-face {
  font-family: 'Helvetica Neue';
  /* font-weight: bold; */
  src: local('Helvetica Neue'), url(../font/HelveticaNeue.ttc) format('OpenType');
}

@font-face {
  font-family: 'Ubuntu';
  font-weight: bold;
  src: local('Ubuntu Bold'), url(../font/Ubuntu-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OxygenMono';
  src: local('OxygenMono'), url(../font/OxygenMono-Regular.ttf) format('truetype');
}



@font-face {
  font-family: 'Ubuntu';
  font-weight: normal;
  src: local('Ubuntu Regular'), url(../font/Ubuntu-Regular.ttf) format('truetype');
}


:root{
  --brand: #36cffc;
  --brand-50: #aeecff;
  --brand-2: #0f83a7;
  --black: #011325;
  --black-2: #888; 
  --text: #444;
  --box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  /* --box-shadow-sm: 5px 5px 10px #9e9e9eaa,  -5px -5px 10px #ffffffcc;
  --box-shadow-lg: 10px 10px 20px #d2d2d2aa,  -10px -10px 20px #ffffffcc;
  --box-shadow-inset: inset 5px 5px 5px #cbced1aa, inset -5px -5px 5px #ffffffcc;
  --box-shadow-inset-sm: inset 5px 5px 5px #9e9e9e77, inset -5px -5px 5px #ffffffee; */
  --bg-gr-down: linear-gradient(145deg, #d4d7dc, #fdffff);

  --success: #60d360;
  --danger: #F43F5E;
}

::selection {
  color: #fff;
  background: var(--brand);
}

:focus{
  outline: none!important;
}


*::before *::after {
  box-sizing: border-box;
}

@font-face {
    font-family: 'DM Mono';
    font-weight: normal;
    src: local('DM Mono'), url(../font/DMMono-Regular.ttf) format('truetype');
  }

body{
    background: var(--black);
    color: #fff;
    font-family: 'Ubuntu', 'Helvetica Neue' , sans-serif;
    /* cursor: none; */
      
}

a{
  color: var(--brand)
}

a:hover{
  text-decoration: none;
  color: var(--brand-2)
}

.ubuntu{
  /* font-family: 'Ubuntu', 'Helvetica Neue', sans-serif; */
  font-weight: bold;
}

.bg-default{
  background-color:var(--black);
}


.lighten{
  mix-blend-mode: screen;
}

*, ::before, ::after{
    box-sizing: border-box;
  }

.text-bold{
    font-weight: bold;
}

.text-brand{
  color: var(--brand)
}


.modal-content{
  background: var(--black);
}



.flat-up{
    background:var(--bg);
    box-shadow:  var(--box-shadow)
}

.down-up{
    background: var(--bg-gr-down);
    box-shadow:  var(--box-shadow)
}

.up-up{
    background: linear-gradient(145deg, #fdffff, #d4d7dc);
    box-shadow:  var(--box-shadow)
}

.flat-down{
    background:var(--bg);
    box-shadow: inset 16px 16px 32px #c9cbcf, 
            inset -16px -16px 32px #ffffff;
}


.navbar-nav .nav-link {
    color: #fff;
}


.number{
    font-family: 'OxygenMono', monospace;
    letter-spacing: -.02em;
    /* font-weight: bold; */
}

.logo-icon{
    height: 1.5em;
}

.btn{
    /* transform: scale(1); */
    transition: all .5s;
}

.btn:hover{
    box-shadow: var(--box-shadow-sm);
    transition: all .5s;
    /* transform: scale(.95); */
  }

  .btn:active{
    box-shadow: var(--box-shadow-sm);
    transition: all .5s;
    /* transform: scale(.95); */
  }
  
  .btn:focus{
    box-shadow: var(--box-shadow-sm);
    transition: all .5s;
    /* transform: scale(.95); */
  }


.btn.disabled, .btn:disabled {
    opacity: 0.3;
}


.nav-link.active{
    color: var(--brand)!important;
}


.hero {
    position: relative;
    z-index: -1;
    text-align: left;
    padding: 40px 0;
}

.card{
    border-radius: .5em;
    background: transparent;
    border: 1px solid var(--black-2);
    transition: all .5s;
}

/* .nav-pills{
  box-shadow: var(--box-shadow);
  border-radius: 10rem;
  padding: 7px;
} */

.nav-link{
  cursor: pointer;
}

.nav-pills .nav-link:hover{
  transition: all .5s;
  /* box-shadow: var(--box-shadow-inset-sm); */
  border-radius: 10rem;
}
.nav-pills .nav-link.active{
  background: transparent;
  /* box-shadow: var(--box-shadow-inset); */
  border-radius: 10rem;
}

.navbar{
    padding: 1.2rem 1rem;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
}

.card-info{
    border-radius: .2em;
}

.card-pro{
    position: absolute;
    background: linear-gradient( 45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100% );
    filter: blur(10px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.token-icon{
    height: 4em;
}

.text-xs{
  font-size: 0.6em;
}

.text-sm{
  font-size: 0.8em;
}

.text-input{
  font-size: 1.1em;
  font-weight: bold;
  padding: .25em;
  word-break: break-all;
}

.text-2{
    font-size: 1.5em;
}

.text-3{
    font-size: 2em;
}
.text-4{
    font-size: 2.5em;
}
.text-5{
    font-size: 3em;
}
.text-6{
    font-size: 4em;
}





.stake-input{
    background: transparent;
    font-size: 1.8em;
    padding: .5em;
    border-radius: .25em;
}
.stake-input:focus{
  border-color: var(--black-2)
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control:focus {
    color: #495057;
    background-color: transparent;
    /* border-color: transparent; */
    outline: none!important;
    box-shadow: none;
}


  
  .loading-title{
    font-size: 1.5em;  
  }


  .brand-highlight {
    background: white;
    color: var(--brand)
}

.btn-brand{
    color: #fff!important;
    background: var(--brand)
}

.btn-success{
  background: var(--success);
  border-color: var(--success);
}

.btn-danger{
  background: var(--danger);
  border-color: var(--danger);
}

.btn-brand:hover{
  color: var(--brand)!important;
  background: var(--brand-50)

}

.btn-outline-brand{
  color: var(--brand)!important;
  background: var(--black);
  border: 1px solid var(--brand)
}

.badge-brand {
  background: var(--brand)!important;
  border: 1px solid var(--brand);
  color: #fff;
}

.badge-brand:hover {
  background: #fff!important;
  border: 1px solid var(--brand);
  color: var(--brand);
}

.btn-outline-brand:hover{
  color: white!important;
  background: var(--brand);
}

.btn-pill{
  border-radius: 10em;
  padding-left: 2em;
  padding-right: 2em;
}


  .img-inline{
      height: 1.3em;
  }

  .img-stroke{
    -webkit-filter: drop-shadow(2px 2px 0 white)
                drop-shadow(-2px 2px 0 white)
                drop-shadow(2px -2px 0 white)
                drop-shadow(-2px -2px 0 white);

    filter: drop-shadow(2px 2px 0 white)
            drop-shadow(-2px 2px 0 white)
            drop-shadow(2px -2px 0 white)
            drop-shadow(-2px -2px 0 white);
  }

  .img-minus{
    margin-left: -1.5em;
  }

  .btn.disabled.btn-swap {
    opacity: .9;
    color: #999;
    /* background:#3dc55d; */
    /* box-shadow: var(--box-shadow-inset); */
}

.btn.disabled.btn-redeem {
  opacity: .9;
  color: #999;
  /* background:#eb505f; */
  /* box-shadow: var(--box-shadow-inset); */
}

.navbar-brand {
  font-size: 1.5rem;
}





#circle-container {
  transform: rotateX(75deg);
  width: 100px;
  height: 100px;
  margin-right: 50%;
  margin-left: auto;
}

.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 1px 12px 0px 0px var(--brand) inset, 5px 10px 0px 25px white inset;
  animation: spin .4s infinite linear;
  -moz-animation: spin .4s infinite linear;
  -webkit-animation: spin .4s infinite linear;
  z-index: 1;
  background: #36cffc77;
}

.circle-two {
  position: absolute;
  z-index: -10;
  top: 70px;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: #36cffc44;
  box-shadow: 2px 5px 0px 0px #36cffc33 inset, 4px 1px 0px 25px white inset;
  animation: spin .2s infinite linear;
  -moz-animation: spin .2s infinite linear;
  -webkit-animation: spin .2s infinite linear;
}

.circle-three {
  position: absolute;
  z-index: -20;
  top: 120px;
  left: 10px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 1px 5px 0px 0px var(--brand) inset, 4px 1px 0px 25px white inset;
  animation: spin .4s infinite linear;
  -moz-animation: spin .4s infinite linear;
  -webkit-animation: spin .4s infinite linear;
  background: #36cffc77;
}

.circle-four {
  position: absolute;
  z-index: -30;
  top: 200px;
  left: 30px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  box-shadow: 5px 0px 0px 0px var(--brand) inset, 4px 1px 0px 25px white inset;
  animation: spin .2s infinite linear;
  -moz-animation: spin .2s infinite linear;
  -webkit-animation: spin .2s infinite linear;
  background: #36cffc77;
}

.circle-five {
  position: absolute;
  z-index: -40;
  top: 245px;
  left: 40px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 5px 0px 0px 1px #36cffc77 inset, 4px 1px 0px 25px #36cffc99 inset;
  animation: spin .3s infinite linear;
  -moz-animation: spin .3s infinite linear;
  -webkit-animation: spin .3s infinite linear;
  background: #36cffcaa;
}

.circle-six {
  position: absolute;
  z-index: -50;
  top: 295px;
  left: 40px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 1px 8px 0px 0px var(--brand) inset, 5px 10px 0px 25px white inset;
  animation: spin .3s infinite linear;
  -moz-animation: spin .3s infinite linear;
  -webkit-animation: spin .3s infinite linear;
  background: #36cffc77;
}
/* 
.circle-seven {
  position: absolute;
  z-index: -60;
  top: 335px;
  left: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 1px 2px 0px 10px dimgrey inset, 3px 1px 0px 5px white inset;
  animation: spin .3s infinite linear;
  -moz-animation: spin .3s infinite linear;
  -webkit-animation: spin .3s infinite linear;
  background: rgba(200,200,200,1);
} */
/* 
.circle-eight {
  position: absolute;
  z-index: -70;
  top: 720px;
  left: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 2px 10px 0px 0px black inset, 4px 1px 0px 50px white inset;
  animation: spin .3s infinite linear;
  -moz-animation: spin .3s infinite linear;
  -webkit-animation: spin .3s infinite linear;
  background: rgba(0,0,0,0.5);
}

.circle-nine {
  position: absolute;
  z-index: -80;
  top: 780px;
  left: 90px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 1px 10px 0px 0px #708090 inset, 4px 1px 0px 50px white inset;
  animation: spin .4s infinite linear;
  -moz-animation: spin .4s infinite linear;
  -webkit-animation: spin .4s infinite linear;
  background: rgba(0,0,0,0.5);
}

.circle-ten {
  position: absolute;
  z-index: -90;
  top: 820px;
  left: 100px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 1px 10px 0px 0px #2F4F4F inset, 4px 1px 0px 50px white inset;
  animation: spin .4s infinite linear;
  -moz-animation: spin .4s infinite linear;
  -webkit-animation: spin .4s infinite linear;
  background: rgba(0,0,0,0.5);
}

.circle-eleven {
  position: absolute;
  z-index: -100;
  top: 840px;
  left: 110px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 1px 10px 0px 0px black inset, 4px 1px 0px 50px white inset;
  animation: spin .2s infinite linear;
  -moz-animation: spin .2s infinite linear;
  -webkit-animation: spin .2s infinite linear;
  background: rgba(0,0,0,0.5);
} */



@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  };
}

@keyframes spinoff {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(-360deg);
  };
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
  };
}

@-webkit-keyframes spinoff {
  0% {
      -webkit-transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(-360deg);
  };
}


.cube-video {
  position: absolute;
  width: 309px;
  top: -66px;
  left: -144px;
  z-index: -1;
}

.maintain-video {
  width: 309px;
}




/* INLINE LOADER */

.loader {
  height : 8px;
  width  : 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */
}

.loader-box {
  display                   : inline-block;
  height                    : 8px;
  width                     : 8px;
  margin                    : 0px 2px;
  background-color          : var(--brand);
  animation-name            : fadeOutIn;
  animation-duration        : 500ms;
  animation-iteration-count : infinite;
  animation-direction       : alternate;
}

.loader-box:nth-child(1) { animation-delay: 250ms; } /* (1/2) * <animation-duration: 500ms */
.loader-box:nth-child(2) { animation-delay: 500ms; } /* (2/2) * <animation-duration: 500ms */
.loader-box:nth-child(3) { animation-delay: 750ms; } /* (3/2) * <animation-duration: 500ms */

@keyframes fadeOutIn {
  0%   { background-color : var(--brand); }
  100% { background-color : #fff; }
}



.tab-title{
  padding: 1em;
  /* background: var(--brand);
  color: #fff; */

  position: relative;
  overflow: hidden;
  display: inline-block;
}

.tab-title:hover, .tab-title.active{
  color: var(--brand);
}


.tab-title.active::after{
  /* background: #fff; */
  background: #fff;
  border-radius: .5em .5em 0 0 ;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* .tab-title:hover::after{
  background: var(--brand);
} */

.tab-title::after{
  position: absolute;
  border-radius: 4px;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--brand);
  content: '';
  -webkit-transition: border-radius .3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: border-radius .3s, background-color 0.3s, transform 0.3s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.7,0,0.3,1);
  transition-timing-function: ease, cubic-bezier(0.7,0,0.3,1);
  -webkit-transform: translate3d(0,100%,0) translate3d(0,-3px,0);
  transform: translate3d(0,100%,0) translate3d(0,-3px,0);
}

.tab-title-container{
  margin-bottom: 0px;
}

.sw__control {
  background: #fff!important;
  padding: .5em;
  font-size: 1.2em;
  color: var(--black)!important;
  border: 1px solid var(--black-2)!important;
  border-radius: .5em!important;
}

.sw__control:focus, .sw__control.sw__control--is-focused{
  outline: none;
  box-shadow: none;
}

.sw__value-container.sw__value-container--has-value {
  background: #fff!important;
}

.sw__single-value{
  color: var(--black)!important;
}

.sw__menu{
  background: #fff !important;
  border: 1px solid var(--black-2)!important;
  border-radius: .5em!important;
  text-align: left;
}

.sw__menu-list{
  padding: 0 !important;
  color: var(--black);
  border-radius: .5em!important;

}

.sw__option{
  padding: 1em!important;
}

/* .sw__option:hover, .sw__option:focus{
  background: var(--brand-2);
} */

.sw__option.sw__option--is-selected{
    background: var(--brand);
}

.sw__indicator{
  color: var(--brand)!important;
}


.card.card-focus{
  background: #fff;
  color: var(--black);
  border-top-right-radius: 0;
  /* border-top-left-radius: 0; */
  text-align: center;
  border: none;
}

.row-title{
  min-width: 10em;
}

.row-title-lg{
  min-width: 15em;
}


.radios {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio input {
  position: absolute;
  pointer-events: none;
  visibility: hidden;
}
.radio input:focus + label {
  background: #d9f6ff;
  color: var(--brand)
}
.radio input:focus + label .checker {
  border-color: var(--brand);
}

.radio label {
  display: flex;
  align-items: center;
  height: 28px;
  border-radius: 14px;
  margin: 10px;
  padding: 0 8px 0 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.radio label:hover {
  background: #d9f6ff;
  color: var(--brand)

}
.radio label:hover .checker {
  box-shadow: inset 0 0 0 2px var(--brand);
}
.radio .checker {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 8px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: box-shadow 0.3s ease;
}

.radio .checker.checked {
  box-shadow: inset 0 0 0 6px var(--brand)!important;
}

textarea.form-control{
  resize: none;
}

.form-tight{
  height: auto;
  padding: .8em;
}

.statis-bar{
  padding: 2em 1em;
  border-radius: .5em;
  border: solid var(--black-2) 1px;
}

.note{
  word-break: break-all;
  font-family: 'OxygenMono', monospace;
  letter-spacing: -.02em;
}